.mathonlp__block2 {
  background: #E2ECFE; }

.mathonlp__block2-holder {
  position: relative; }
  @media only screen and (max-width: 1019px) {
    .mathonlp__block2-holder {
      padding-top: 64px;
      padding-bottom: 64px; } }
  @media only screen and (min-width: 1020px) {
    .mathonlp__block2-holder {
      padding-top: 80px;
      padding-bottom: 80px; } }

.mathonlp__block2-title {
  font-weight: 700;
  text-align: center;
  color: #0E1952; }
  @media only screen and (max-width: 1019px) {
    .mathonlp__block2-title {
      font-size: 28px;
      line-height: 40px; } }
  @media only screen and (min-width: 1020px) {
    .mathonlp__block2-title {
      font-size: 40px;
      line-height: 48px; } }
  .mathonlp__block2-title .highlight {
    color: #0E63F4; }

.mathonlp__block2-list {
  display: flex; }
  @media only screen and (max-width: 1019px) {
    .mathonlp__block2-list {
      margin-top: 40px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch; } }
  @media only screen and (min-width: 1020px) {
    .mathonlp__block2-list {
      margin-top: 48px;
      gap: 24px; } }

.mathonlp__block2-item {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  border-radius: 24px;
  background: #FFF; }
  @media only screen and (max-width: 1019px) {
    .mathonlp__block2-item {
      min-height: 254px; } }
  @media only screen and (min-width: 1020px) {
    .mathonlp__block2-item {
      min-height: 280px;
      flex: 1 0 0; } }

.mathonlp__block2-item-title {
  color: #0E1952;
  font-size: 22px;
  font-weight: 700;
  line-height: 28px; }

.mathonlp__block2-item-text {
  color: #0E1952;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px; }

.mathonlp__block2-doodle-img {
  position: absolute; }
  @media only screen and (max-width: 1019px) {
    .mathonlp__block2-doodle-img {
      width: 93px;
      height: 67px;
      right: 0;
      top: 0; } }
  @media only screen and (min-width: 1020px) {
    .mathonlp__block2-doodle-img {
      right: -140px;
      top: 80px; } }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
