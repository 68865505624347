@media only screen and (max-width: 1019px) {
  .services-block__type1 .holder {
    margin-top: 30px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type1 .holder {
    margin-top: 40px; } }

.services-block__type1 .services__title {
  font-family: "Aleo", serif;
  font-size: 35px;
  line-height: 1.4;
  letter-spacing: 0.7px;
  text-align: center;
  color: #1c2036; }

@media only screen and (max-width: 1019px) {
  .services-block__type1 .services-list:not(:first-child) {
    position: relative; }
    .services-block__type1 .services-list:not(:first-child):before {
      content: '';
      position: absolute;
      width: 290px;
      height: 1px;
      background-color: rgba(228, 229, 235, 0.8);
      bottom: 0;
      left: 50%;
      margin-left: -145px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type1 .services-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 49px 51px;
    margin-top: 37px; } }

@media only screen and (max-width: 1019px) {
  .services-block__type1 .services-list-item {
    padding-top: 50px;
    padding-bottom: 44px;
    text-align: center; }
    .services-block__type1 .services-list-item:not(:first-child) {
      position: relative; }
      .services-block__type1 .services-list-item:not(:first-child):before {
        content: '';
        position: absolute;
        width: 200px;
        height: 1px;
        background-color: rgba(228, 229, 235, 0.8);
        top: 0;
        left: 50%;
        margin-left: -100px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type1 .services-list-item {
    width: 468px;
    border-radius: 10px;
    box-shadow: 0 2px 7px 0 rgba(28, 32, 54, 0.19);
    background-color: #fff;
    padding: 24px;
    display: flex;
    justify-content: space-between; } }

@media only screen and (min-width: 1020px) {
  .services-block__type1 .services-list-block1 {
    flex-basis: 234px;
    order: 2; } }

@media only screen and (max-width: 1019px) {
  .services-block__type1 .services-list-img.img1 {
    width: 65px;
    height: 80px; }
  .services-block__type1 .services-list-img.img2 {
    width: 65px;
    height: 80px; }
  .services-block__type1 .services-list-img.img3 {
    width: 95px;
    height: 88px; }
  .services-block__type1 .services-list-img.img4 {
    width: 65px;
    height: 80px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type1 .services-list-img {
    display: none; } }

.services-block__type1 .services-list-title {
  font-family: "Aleo", serif;
  font-size: 24px;
  letter-spacing: 0.3px;
  color: #4e526b; }
  @media only screen and (max-width: 1019px) {
    .services-block__type1 .services-list-title {
      line-height: 1.4;
      margin-top: 16px; } }
  @media only screen and (min-width: 1020px) {
    .services-block__type1 .services-list-title {
      line-height: 1.25; } }

.services-block__type1 .services-list-label {
  font-family: "Aleo", serif;
  font-size: 20px;
  letter-spacing: 0.3px;
  color: #ff7d2b; }
  @media only screen and (max-width: 1019px) {
    .services-block__type1 .services-list-label {
      display: inline-block;
      height: 39px;
      line-height: 39px;
      border-radius: 21px;
      border: solid 1px #ff7d2b;
      background-color: #fff;
      margin-top: 28px;
      padding: 0 25px;
      font-weight: bold; } }
  @media only screen and (min-width: 1020px) {
    .services-block__type1 .services-list-label {
      margin-top: 8px;
      line-height: 1.5; } }

.services-block__type1 .services-list-info {
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  color: #4e526b; }
  @media only screen and (max-width: 1019px) {
    .services-block__type1 .services-list-info {
      font-size: 18px;
      margin-top: 16px; } }
  @media only screen and (min-width: 1020px) {
    .services-block__type1 .services-list-info {
      font-size: 16px;
      margin-top: 24px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type1 .services-list-block2 {
    width: 164px; } }

@media only screen and (max-width: 1019px) {
  .services-block__type1 .screenshot-label {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 1.5;
    color: #797c91;
    margin-top: 32px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type1 .screenshot-label {
    display: none; } }

.services-block__type1 .screenshot-wrapper {
  position: relative; }
  @media only screen and (max-width: 1019px) {
    .services-block__type1 .screenshot-wrapper {
      width: 214px;
      margin: 10px auto 0; } }

.services-block__type1 .preview-img {
  width: 100%; }

.services-block__type1 .screenshot-zoom {
  position: absolute;
  top: 50%;
  left: 50%; }
  @media only screen and (max-width: 1019px) {
    .services-block__type1 .screenshot-zoom {
      margin-left: -30px;
      margin-top: -30px; } }
  @media only screen and (min-width: 1020px) {
    .services-block__type1 .screenshot-zoom {
      margin-left: -20px;
      margin-top: -20px; } }

.services-block__type1 .zoom-icon {
  display: block; }
  @media only screen and (max-width: 1019px) {
    .services-block__type1 .zoom-icon {
      width: 60px;
      height: 60px; } }
  @media only screen and (min-width: 1020px) {
    .services-block__type1 .zoom-icon {
      width: 40px;
      height: 40px; } }

@media only screen and (max-width: 1019px) {
  .services-block__type1 .download-preview-link {
    display: none; } }

@media only screen and (min-width: 1020px) {
  .services-block__type1 .download-preview-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type1 .pdf-icon {
    width: 16px;
    height: 16px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type1 .download-preview-link-msg {
    margin-left: 8px;
    display: block;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    line-height: 1.92;
    letter-spacing: 0.2px;
    color: #24a652; } }

.services-block__type1 .pair-screenshot-wrapper {
  position: relative; }
  @media only screen and (max-width: 1019px) {
    .services-block__type1 .pair-screenshot-wrapper {
      width: 100%;
      height: 320px;
      margin-top: 16px; } }
  @media only screen and (min-width: 1020px) {
    .services-block__type1 .pair-screenshot-wrapper {
      width: 164px;
      height: 160px; } }
  .services-block__type1 .pair-screenshot-wrapper:before, .services-block__type1 .pair-screenshot-wrapper:after {
    position: absolute;
    font-family: "Roboto", sans-serif;
    font-weight: bold; }
    @media only screen and (max-width: 1019px) {
      .services-block__type1 .pair-screenshot-wrapper:before, .services-block__type1 .pair-screenshot-wrapper:after {
        font-size: 16px;
        line-height: 1.5;
        color: #4e526b; } }
    @media only screen and (min-width: 1020px) {
      .services-block__type1 .pair-screenshot-wrapper:before, .services-block__type1 .pair-screenshot-wrapper:after {
        font-size: 11px;
        line-height: 2.18;
        color: #797c91; } }
  .services-block__type1 .pair-screenshot-wrapper:before {
    content: 'Before'; }
    @media only screen and (max-width: 1019px) {
      .services-block__type1 .pair-screenshot-wrapper:before {
        bottom: 23px;
        left: 14px; } }
    @media only screen and (min-width: 1020px) {
      .services-block__type1 .pair-screenshot-wrapper:before {
        bottom: 8px;
        left: 7px; } }
  .services-block__type1 .pair-screenshot-wrapper:after {
    content: 'After'; }
    @media only screen and (max-width: 1019px) {
      .services-block__type1 .pair-screenshot-wrapper:after {
        top: 23px;
        right: 14px; } }
    @media only screen and (min-width: 1020px) {
      .services-block__type1 .pair-screenshot-wrapper:after {
        top: 8px;
        right: 7px; } }
  .services-block__type1 .pair-screenshot-wrapper .screenshot-wrapper {
    position: absolute; }
    @media only screen and (max-width: 1019px) {
      .services-block__type1 .pair-screenshot-wrapper .screenshot-wrapper {
        margin: 0;
        width: 194px;
        height: 269px; } }
    @media only screen and (min-width: 1020px) {
      .services-block__type1 .pair-screenshot-wrapper .screenshot-wrapper {
        width: 94px;
        height: 128px; } }
    .services-block__type1 .pair-screenshot-wrapper .screenshot-wrapper:first-child {
      top: 0;
      left: 0; }
    .services-block__type1 .pair-screenshot-wrapper .screenshot-wrapper:last-child {
      bottom: 0;
      right: 0; }

@media only screen and (max-width: 1019px) {
  .services-block__type2 .holder {
    margin-top: 55px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type2 .holder {
    margin-top: 20px;
    margin-bottom: 90px; } }

.services-block__type2 .services__title {
  font-family: "Aleo", serif;
  text-align: center; }
  @media only screen and (max-width: 1019px) {
    .services-block__type2 .services__title {
      font-size: 26px;
      line-height: 1.4;
      letter-spacing: 0.36px;
      color: #31354f; } }
  @media only screen and (min-width: 1020px) {
    .services-block__type2 .services__title {
      font-size: 29px;
      line-height: 1.24;
      letter-spacing: 0.4px;
      color: #1c2036; } }

@media only screen and (max-width: 1019px) {
  .services-block__type2 .services-list {
    position: relative;
    padding-bottom: 50px;
    margin-top: 43px;
    margin-bottom: 50px; }
    .services-block__type2 .services-list:after {
      content: '';
      position: absolute;
      width: 300px;
      height: 1px;
      background-color: rgba(228, 229, 235, 0.8);
      bottom: 0;
      left: 50%;
      margin-left: -150px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type2 .services-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 32px;
    margin-top: 50px; } }

.services-block__type2 .services-list-item {
  display: flex; }
  @media only screen and (max-width: 1019px) {
    .services-block__type2 .services-list-item {
      justify-content: space-between;
      align-items: flex-start; }
      .services-block__type2 .services-list-item:not(:first-child) {
        padding-top: 60px; } }
  @media only screen and (min-width: 1020px) {
    .services-block__type2 .services-list-item {
      width: 307px;
      border-radius: 10px;
      box-shadow: 0 2px 7px 0 rgba(28, 32, 54, 0.19);
      background-color: #fff;
      padding: 18px 25px 27px;
      flex-direction: column;
      justify-content: center;
      align-items: center; } }

@media only screen and (max-width: 1019px) {
  .services-block__type2 .services-list-img {
    display: block; }
    .services-block__type2 .services-list-img.img1 {
      width: 72px;
      height: 97px; }
    .services-block__type2 .services-list-img.img2 {
      width: 80px;
      height: 93px; }
    .services-block__type2 .services-list-img.img3 {
      width: 77px;
      height: 92.4px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type2 .services-list-img {
    display: none; } }

@media only screen and (max-width: 1019px) {
  .services-block__type2 .services-list-block {
    width: 236px; } }

.services-block__type2 .services-list-title {
  font-family: "Aleo", serif;
  font-size: 24px;
  letter-spacing: 0.32px;
  color: #4e526b; }
  @media only screen and (max-width: 1019px) {
    .services-block__type2 .services-list-title {
      line-height: 1.4; } }
  @media only screen and (min-width: 1020px) {
    .services-block__type2 .services-list-title {
      line-height: 1.25;
      text-align: center; } }

@media only screen and (max-width: 1019px) {
  .services-block__type2 .screenshot-wrapper {
    display: none; } }

@media only screen and (min-width: 1020px) {
  .services-block__type2 .screenshot-wrapper {
    position: relative;
    width: 114px;
    height: 154px;
    margin: 24px auto 12px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type2 .preview-img {
    width: 100%; } }

@media only screen and (min-width: 1020px) {
  .services-block__type2 .screenshot-zoom {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -20px;
    margin-top: -20px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type2 .zoom-icon {
    display: block;
    width: 40px;
    height: 40px; } }

@media only screen and (max-width: 1019px) {
  .services-block__type2 .download-preview-link {
    display: none; } }

@media only screen and (min-width: 1020px) {
  .services-block__type2 .download-preview-link {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type2 .pdf-icon {
    width: 16px;
    height: 16px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type2 .download-preview-link-msg {
    margin-left: 8px;
    display: block;
    font-family: "Roboto", sans-serif;
    font-size: 13px;
    line-height: 1.92;
    letter-spacing: 0.2px;
    color: #24a652; } }

@media only screen and (max-width: 1019px) {
  .services-block__type2 .show-example-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 221px;
    height: 34px;
    border-radius: 17.5px;
    background-color: #24a652;
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #fff;
    margin-top: 20px; } }

@media only screen and (min-width: 1020px) {
  .services-block__type2 .show-example-btn {
    display: none; } }

.services-block__type2 .services-list-label {
  font-family: "Aleo", serif;
  font-size: 20px;
  letter-spacing: 0.3px;
  color: #ff7d2b;
  margin-top: 8px;
  line-height: 1.5; }
  @media only screen and (min-width: 1020px) {
    .services-block__type2 .services-list-label {
      text-align: center; } }

.services-block__type2 .services-list-info {
  font-family: "Roboto", sans-serif;
  line-height: 1.5;
  color: #4e526b; }
  @media only screen and (max-width: 1019px) {
    .services-block__type2 .services-list-info {
      font-size: 18px;
      margin-top: 16px; } }
  @media only screen and (min-width: 1020px) {
    .services-block__type2 .services-list-info {
      font-size: 16px;
      margin-top: 14px;
      text-align: center; } }

/*
@include respond-to(handhelds) {
}
@include respond-to(wide-screens) {
}
*/
