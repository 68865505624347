.mathon-content {
  max-width: 776px;
  margin: 0 auto;
  padding: 80px 16px 80px 16px;
  font-family: "Manrope", sans-serif;
  color: #0E1952; }
  .mathon-content a {
    text-decoration-line: underline; }
  .mathon-content .mathon-h1 {
    font-weight: 700; }
    @media only screen and (max-width: 1019px) {
      .mathon-content .mathon-h1 {
        font-size: 40px;
        line-height: 56px; } }
    @media only screen and (min-width: 1020px) {
      .mathon-content .mathon-h1 {
        font-size: 64px;
        line-height: 80px; } }
  .mathon-content .mathon-h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
    margin: 48px 0 16px; }
  .mathon-content .mathon-p,
  .mathon-content .mathon-li {
    font-size: 20px;
    line-height: 32px; }
  .mathon-content .mathon-p {
    margin-top: 16px; }
  .mathon-content .mathon-ul {
    margin-top: 48px; }
  .mathon-content .mathon-li {
    position: relative;
    padding-left: 26px; }
    .mathon-content .mathon-li:before {
      content: '';
      background: #0E1952;
      display: block;
      position: absolute;
      top: 14px;
      left: 9px;
      width: 4px;
      height: 4px;
      border-radius: 50%; }
  .mathon-content .highlight {
    text-decoration: underline; }
  .mathon-content .itailic {
    font-style: italic; }

/*
.mathon-terms__date {
  font-size: 16px;
  line-height: 1.5;
  font-style: italic;
  margin-top: 28px;
}
*/
